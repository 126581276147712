import { z } from "zod";

import { resultSchema } from "@schemas/result.schema.ts";

export const objectiveSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  order: z.number().positive().min(1),
  hasLineAbove: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const selectedResultSchema = z.object({
  isImportant: z.boolean(),
  objective: objectiveSchema,
  objectiveId: z.string().uuid(),
  reportId: z.string().uuid(),
  result: resultSchema,
  resultId: z.string().uuid(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type SelectedResult = z.infer<typeof selectedResultSchema>;
