import { z } from "zod";

import { objectiveSchema } from "@schemas/objective.schema.ts";
import {
  createReportSchema,
  fullReportSchema,
  reportSchema,
  updateResultSchema,
} from "@schemas/report.schema.ts";
import api from "@utils/api.util.ts";

export const getReportsApi = api({
  method: "get",
  path: "/reports",
  requestSchema: z.void(),
  responseSchema: reportSchema.array(),
});

export const getReportApi = (id: string) =>
  api({
    method: "get",
    path: `/reports/${id}`,
    requestSchema: z.void(),
    responseSchema: fullReportSchema,
  })();

export const getObjectivesApi = api({
  method: "get",
  path: "/reports/objectives",
  requestSchema: z.void(),
  responseSchema: objectiveSchema.array(),
});

export const createReportApi = api({
  method: "post",
  path: "/reports",
  requestSchema: createReportSchema,
  responseSchema: reportSchema,
});

export const updateResultApi = (id: string) =>
  api({
    method: "patch",
    path: `/reports/${id}`,
    requestSchema: updateResultSchema,
    responseSchema: z.any(),
  });

export const generateGroupCopyApi = (id: string, objectiveIdFrom?: string) =>
  api({
    method: "get",
    path: `/reports/generate/group/copy/${id}?${new URLSearchParams(objectiveIdFrom ? { objectiveIdFrom } : undefined).toString()}`,
    requestSchema: z.void(),
    responseSchema: z.string(),
  });

export const generateFileApi = (id: string) =>
  api({
    method: "post",
    path: `/reports/generate/file/${id}`,
    requestSchema: z.void(),
    responseSchema: z.object({
      downloadUrl: z.string().url(),
    }),
  });

export const generateEmailApi = (id: string) =>
  api({
    method: "post",
    path: `/reports/generate/email/${id}`,
    requestSchema: z.void(),
    responseSchema: z.any(),
  });

export const deleteReportApi = (id: string) =>
  api({
    method: "delete",
    path: `/reports/${id}`,
    requestSchema: z.void(),
    responseSchema: z.any(),
  })();
